import React from 'react'
import img from '../../images/elektrostymulacja-nogi.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Elektrostymulacja = () => {
  return (
    <>
      <Layout>
        <Seo
          title='Elektrostymulacja | Modelowanie Sylwetki'
          description='Elektrostymulacja mięśni, nazywana także EMS (Electrical Muscle
          Stimulation) to zabieg, którego celem jest wywołanie reakcji
          motorycznej mięśnia lub grupy mięśni przy wykorzystaniu impulsów
          elektrycznych.'
          url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/elektrostymulacja'
        />

        <div className='page-container'>
          <div className='page'>
            <div className='content-1'>
              <h1>Elektrostymulacja</h1>
              <div className='section'>
                <div className='image-container'>
                  <img
                    src={img}
                    width='1000px'
                    height='667px'
                    alt='Elektrostymulacja zdjęcie'
                  ></img>
                </div>
                <div className='text'>
                  <p>
                    Elektrostymulacja mięśni, nazywana także EMS (Electrical Muscle
                    Stimulation) to zabieg, którego celem jest wywołanie reakcji
                    motorycznej mięśnia lub grupy mięśni przy wykorzystaniu impulsów
                    elektrycznych. W zależności od wyboru programu, który trwa 30 minut i
                    trybu pracy urządzenia, impulsy, wprawiające w ruch mięśnie, mogą
                    działać terapeutycznie i rehabilitacyjnie lub dawać efekty
                    kosmetyczne. Dzięki zabiegom elektrostymulacji możliwa jest nie tylko
                    redukcja cellulitu, ujędrnienie skóry ciała, zredukowanie tkanki
                    tłuszczowej czy wymodelowanie sylwetki, ale także przywrócenie
                    utraconej funkcji mięśnia czy zmniejszenie obrzęków.
                  </p>
                  <div className='cennik'>
                    <span>CENNIK</span>
                    <h2>ELEKTROSTYMULACJA</h2>
                    <ul>
                      <li>
                        <p>ELEKTROSTYMULACJA - 1 PARTIA - POJEDZYNCZY ZABIEG</p>
                        <span className='prices'>30 MIN | 40 zł</span>
                      </li>
                      <li>
                        <p>ELEKTROSTYMULACJA - 2 PARTIA - POJEDZYNCZY ZABIEG</p>
                        <span className='prices'>30 MIN | 55 zł</span>
                      </li>
                      <li>
                        <p>ELEKTROSTYMULACJA - 3 PARTIA - POJEDZYNCZY ZABIEG</p>
                        <span className='prices'>30 MIN | 65 zł</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Fade left>
              <Link to='/kontakt'>
                <button>Umów Wizytę</button>
              </Link>
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Elektrostymulacja
